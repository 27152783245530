







































import {Component, Vue} from 'vue-property-decorator';

import {makePassphraseAsync} from '@/api/memberShip'

@Component({})
export default class UserIndex extends Vue {
  private show = false
  private passphrase = ''

  // 密码短语弹出
  private handleMM() {
    this.show = true
  }

  private async activated() {
    const passphrase = localStorage.getItem('Keep_Passphrase')
    if (passphrase) {
      this.passphrase = passphrase
    }
  }

  private async makePass() {
    const result: any = await makePassphraseAsync()
    if (result) {
      localStorage.setItem('Keep_Passphrase', result)
      this.passphrase = result
    }
  }

  private async handleLogout() {
    Vue.prototype.$Cookies.remove('Keep_Token', {path: '/'})
    localStorage.removeItem('Keep_OpenId')
    localStorage.removeItem('Keep_NickName')
    localStorage.removeItem('Keep_FaceUrl')
    localStorage.removeItem('Keep_ShipType')
    localStorage.removeItem('Keep_RealName')
    localStorage.removeItem('Keep_Passphrase')
    localStorage.removeItem('Keep_Phone')
    this.$toast('退出成功')
    location.reload()
  }
};
